// Base Imports
import React, { useCallback, useEffect } from 'react'

// Package Imports
import Router from 'next/router'

// Other Imports
import { matchStrings } from '@framework/utils/parse-util'
import { Messages } from '@components/utils/constants'
import {
  PaymentMethodMode,
  PaymentMethodType,
} from '@components/utils/payment-constants'
import {
  errorSubmitData,
  getDeliveryAddress,
  getOrderId,
  getOrderInfo,
  resetSubmitData,
} from '@framework/utils/app-util'
import useDataSubmit from '@commerce/utils/use-data-submit'
import { useMoEngageEvent } from 'hooks/useMoengageEvent'
import { defaultCheckoutPayment, isPrimaryNB } from './helper'
import dynamic from 'next/dynamic'
import {
  beginBasketExpressCheckout,
  handlePurchaseInitialtedAfterFailureGAEvent,
} from './eventsHelper'
import { useBasketExpressCheckout } from './Hooks/useBasketExpressCheckout'
import { useBasketExpressCheckoutTransactions } from './Hooks/useBasketExpressCheckoutTransactions'
import { JUSPAY_OFFERS_APPLY_COUPON_ENABLED } from '@framework/utils/constants'
import { createNetbankingTransaction } from '@framework/utils/juspay-util'
import { getSessionItem } from '@components/utils/sessionStorage'
import { useUI } from '@components/ui'
import {
  IOffersInput,
  IS_PAYMENT_REDIRECT_ENABLED,
  NETBANKING_OPTIONS,
} from '@components/checkout/helper'
import Cookies from 'js-cookie'
import { LocalStorage } from '@components/utils/payment-constants'

const BasketExpressCheckoutMainSection = dynamic(
  import('./BasketExpressCheckoutMainSection'),
  { ssr: false }
)

const BasketExpressCheckout = ({
  preferredPaymentMethod,
  config,
  savedCard,
  useForRedirectionOnly = false,
  startExpressCheckout = false,
  startPayOnDelivery = false,
  isBackFromFailure = false,
  reValidateData = null,
  setBasketReValidate = null,
  showBasketRevalidateError = false,
  deviceInfo,
  isChannelStore,
}: any) => {
  const { dispatch } = useDataSubmit()
  const trackMoEngageEvent = useMoEngageEvent()
  const { isGuestUser } = useUI()
  const sessionStoredUser = getSessionItem('isUserAlreadyExist')
  const isUserAlreadyExist = sessionStoredUser
    ? JSON.parse(sessionStoredUser)
    : true
  const isGoKwikUser = !isUserAlreadyExist
  const {
    fetchAddress,
    handleFetchOffers,
    handleConvertOrder,
    handlePaymentMethods,
    initiateJusPayOrder,
    showLoader,
    hideLoader,
    setDefaultShippingAddress,
    defaultShippingAddress,
    storeId,
    applicableCoupon,
    user,
    cartItems,
    setAlert,
    getUserId,
    shippingCountry,
    confirmOrder,
  } = useBasketExpressCheckout({ config, isChannelStore })

  const { upiPayTransaction, walletPayTransaction, payOnDeliveryTransaction } =
    useBasketExpressCheckoutTransactions({
      isChannelStore,
      showLoader,
      deviceInfo,
      handleFetchOffers,
      handleConvertOrder,
      initiateJusPayOrder,
      applicableCoupon,
      hideLoader,
      dispatch,
      defaultCheckoutPayment,
      confirmOrder,
      getUserId,
      storeId,
      defaultShippingAddress,
      isGoKwikUser,
      trackMoEngageEvent,
      shippingCountry,
    })

  const netbankingPayTransaction = useCallback(
    async (preferredPaymentMethod: any) => {
      showLoader()
      if (preferredPaymentMethod?.key) {
        let nbKey = `${preferredPaymentMethod?.key}_${preferredPaymentMethod?.type}`
        const option = NETBANKING_OPTIONS?.find((x: any) =>
          matchStrings(x.key, nbKey, true)
        )
        if (option?.name) {
          nbKey = option.key
        }
        if (nbKey) {
          if (JUSPAY_OFFERS_APPLY_COUPON_ENABLED) {
            const params: IOffersInput = {
              methodType: PaymentMethodType.NETBANKING,
              methodRef: `${option?.name} BANK`,
              extras: {
                payment_method: nbKey,
              },
            }
            const offersResult = await handleFetchOffers(params)
          }

          showLoader()
          const success = await handleConvertOrder() // Moved here from component load

          if (success) {
            const jusPayOrderResult: any = await initiateJusPayOrder(
              PaymentMethodType.NETBANKING,
              false,
              `${nbKey}_${option?.name}`
            )
            if (jusPayOrderResult?.orderId) {
              const orderInfo = await getOrderInfo()
              let netBankingInput = {
                order_id: getOrderId(orderInfo?.order),
                payment_method_type: 'NB',
                payment_method: nbKey,
                redirect_after_payment: true,
                format: 'json',
              }
              if (
                applicableCoupon?.offers?.length &&
                applicableCoupon?.offers[0]?.offer_id
              ) {
                netBankingInput = {
                  ...netBankingInput,
                  ...{
                    offers: JSON.stringify([
                      applicableCoupon?.offers[0]?.offer_id,
                    ]),
                  },
                }
              }
              const authToken =
                localStorage?.getItem(LocalStorage.Key.userEncryptedDetails) ??
                ''
              const user = localStorage?.getItem('user')
              const userDetails = user ? JSON.parse(user) : {}
              const userId = userDetails?.userId
              const netBankingResult = await createNetbankingTransaction(
                netBankingInput,
                authToken,
                userId
              )

              if (netBankingResult?.hasError) {
                resetSubmitData(dispatch)
                errorSubmitData(dispatch, netBankingResult?.error, true)
              } else {
                if (IS_PAYMENT_REDIRECT_ENABLED && netBankingResult?.url) {
                  resetSubmitData(dispatch)
                  Router.push(netBankingResult?.url)
                }
              }
            } else {
              resetSubmitData(dispatch)
              errorSubmitData(dispatch, { error_code: 'GENERIC_ERROR' })
            }
          } else {
            hideLoader()
            resetSubmitData(dispatch)
          }
        } else {
          hideLoader()
        }
      } else {
        hideLoader()
      }
    },
    [
      applicableCoupon?.offers,
      dispatch,
      handleConvertOrder,
      handleFetchOffers,
      hideLoader,
      initiateJusPayOrder,
      showLoader,
    ]
  )

  const expressCheckoutPayment = useCallback(
    async (preferredPaymentMethod: any) => {
      if (matchStrings(preferredPaymentMethod?.method, PaymentMethodType.UPI)) {
        upiPayTransaction(preferredPaymentMethod)
        if (isBackFromFailure) {
          handlePurchaseInitialtedAfterFailureGAEvent(user, 'UPI')
        }
      } else if (
        matchStrings(preferredPaymentMethod?.method, PaymentMethodType.WALLET)
      ) {
        walletPayTransaction(preferredPaymentMethod)
        if (isBackFromFailure) {
          handlePurchaseInitialtedAfterFailureGAEvent(user, 'WALLET')
        }
      } else if (
        matchStrings(
          preferredPaymentMethod?.method,
          PaymentMethodType.NETBANKING
        )
      ) {
        netbankingPayTransaction(preferredPaymentMethod)
        if (isBackFromFailure) {
          handlePurchaseInitialtedAfterFailureGAEvent(user, 'NETBANKING')
        }
      } else if (
        matchStrings(preferredPaymentMethod?.method, PaymentMethodType.COD)
      ) {
        payOnDeliveryTransaction(preferredPaymentMethod)
        if (isBackFromFailure) {
          handlePurchaseInitialtedAfterFailureGAEvent(user, 'COD')
        }
      } else if (
        matchStrings(preferredPaymentMethod?.method, PaymentMethodType.CARD)
      ) {
        if (isChannelStore) {
          Router.push({
            pathname: `/checkout/payment/${defaultShippingAddress?.id}`,
            query: {
              channelStore: true,
              storeId: storeId,
            },
          })
        } else {
          Router.push(`/checkout/payment/${defaultShippingAddress?.id}`)
        }
        if (isBackFromFailure) {
          handlePurchaseInitialtedAfterFailureGAEvent(user, 'CARD')
        }
      } else {
        defaultCheckoutPayment({
          defaultShippingAddress,
          isChannelStore,
          storeId,
        })
        if (isBackFromFailure) {
          handlePurchaseInitialtedAfterFailureGAEvent(user, 'DIFFERENT')
        }
      }
    },
    [
      defaultShippingAddress,
      isBackFromFailure,
      isChannelStore,
      netbankingPayTransaction,
      payOnDeliveryTransaction,
      storeId,
      upiPayTransaction,
      user,
      walletPayTransaction,
    ]
  )

  const initiateExpressCheckout = useCallback(async () => {
    if (defaultShippingAddress?.id) {
      if (matchStrings(preferredPaymentMethod?.method, PaymentMethodType.UPI)) {
        await expressCheckoutPayment(preferredPaymentMethod)

        if (isBackFromFailure) {
          handlePurchaseInitialtedAfterFailureGAEvent(user, 'UPI')
        }
      } else if (
        matchStrings(
          preferredPaymentMethod?.method,
          PaymentMethodType.CARD,
          true
        )
      ) {
        if (isChannelStore) {
          Router.push({
            pathname: `/checkout/payment/${defaultShippingAddress?.id}?m=${PaymentMethodMode.CARD}`,
            query: {
              channelStore: true,
              storeId: storeId,
            },
          })
        } else {
          Router.push(
            `/checkout/payment/${defaultShippingAddress?.id}?m=${PaymentMethodMode.CARD}`
          )
        }

        if (isBackFromFailure) {
          handlePurchaseInitialtedAfterFailureGAEvent(user, 'CARD')
        }
      } else {
        // Consider anything apart from UPI or Card AS NORMAL CHECKOUT.
        if (isChannelStore) {
          Router.push({
            pathname: `/checkout/payment/${defaultShippingAddress?.id}`,
            query: {
              channelStore: true,
              storeId: storeId,
            },
          })
        } else {
          Router.push(`/checkout/payment/${defaultShippingAddress?.id}`)
        }

        if (isBackFromFailure) {
          handlePurchaseInitialtedAfterFailureGAEvent(user, 'DIFFERENT')
        }
      }
      beginBasketExpressCheckout(
        cartItems,
        user,
        preferredPaymentMethod,
        defaultShippingAddress,
        isChannelStore,
        trackMoEngageEvent
      )
    } else {
      if (isChannelStore) {
        Router.push({
          pathname: `/checkout`,
          query: {
            channelStore: true,
            storeId: storeId,
          },
        })
      } else {
        Router.push(`/checkout`)
      }

      if (isBackFromFailure) {
        handlePurchaseInitialtedAfterFailureGAEvent(user, 'DIFFERENT')
      }
      beginBasketExpressCheckout(
        cartItems,
        user,
        preferredPaymentMethod,
        defaultShippingAddress,
        isChannelStore,
        trackMoEngageEvent
      )
    }
  }, [
    cartItems,
    defaultShippingAddress,
    expressCheckoutPayment,
    isBackFromFailure,
    isChannelStore,
    preferredPaymentMethod,
    storeId,
    trackMoEngageEvent,
    user,
  ])

  const initiatePayOnDelivery = useCallback(async () => {
    if (cartItems && cartItems?.lineItems?.length) {
      if (isGuestUser) {
        const deliveryAddress = getDeliveryAddress()
        if (deliveryAddress?.id) {
          if (isChannelStore) {
            Router.push({
              pathname: `/checkout/payment/${deliveryAddress?.id}?m=${PaymentMethodMode.COD}`,
              query: {
                channelStore: true,
                storeId: storeId,
              },
            })
          } else {
            Router.push(
              `/checkout/payment/${deliveryAddress?.id}?m=${PaymentMethodMode.COD}`
            )
          }
        } else {
          if (isChannelStore) {
            Router.push({
              pathname: '/checkout',
              query: {
                channelStore: true,
                storeId: storeId,
              },
            })
          } else {
            Router.push('/checkout')
          }
        }
      } else {
        payOnDeliveryTransaction(preferredPaymentMethod)
        beginBasketExpressCheckout(
          cartItems,
          user,
          preferredPaymentMethod,
          defaultShippingAddress,
          isChannelStore,
          trackMoEngageEvent
        )
      }
    } else {
      setAlert({ type: 'error', msg: Messages.Errors['CART_EMPTY'] })
    }
  }, [
    cartItems,
    defaultShippingAddress,
    isChannelStore,
    isGuestUser,
    payOnDeliveryTransaction,
    preferredPaymentMethod,
    setAlert,
    storeId,
    trackMoEngageEvent,
    user,
  ])

  const handleFetchAddress = useCallback(async () => {
    await fetchAddress(getUserId())
  }, [fetchAddress, getUserId])

  const handleAsync = useCallback(async () => {
    const deliveryAddress = getDeliveryAddress()
    if (deliveryAddress?.id) {
      setDefaultShippingAddress(deliveryAddress)
    } else {
      const promises = new Array<Promise<any>>()
      promises.push(
        await new Promise<any>(async (resolve: any, reject: any) => {
          if (!isGuestUser) {
            await handleFetchAddress()
          }
          resolve()
        })
      )

      Promise.all(promises)
    }

    if (
      matchStrings(
        preferredPaymentMethod?.method,
        PaymentMethodType.NETBANKING,
        true
      )
    ) {
      if (!isPrimaryNB(preferredPaymentMethod)) {
        await handlePaymentMethods()
      }
    }
  }, [
    handlePaymentMethods,
    isGuestUser,
    preferredPaymentMethod,
    handleFetchAddress,
    setDefaultShippingAddress,
  ])

  useEffect(() => {
    handleAsync()
  }, [])

  useEffect(() => {
    const asyncHandler = async () => {
      await initiateExpressCheckout()
    }
    if (startExpressCheckout) {
      asyncHandler()
    }
  }, [startExpressCheckout])

  useEffect(() => {
    const asyncHandler = async () => {
      await initiatePayOnDelivery()
    }
    if (startPayOnDelivery) {
      asyncHandler()
    }
  }, [startPayOnDelivery])

  return !useForRedirectionOnly ? (
    <>
      {/* Footer enable when login with last payment*/}
      <BasketExpressCheckoutMainSection
        defaultShippingAddress={defaultShippingAddress}
        reValidateData={reValidateData}
        isChannelStore={isChannelStore}
        setBasketReValidate={setBasketReValidate}
        preferredPaymentMethod={preferredPaymentMethod}
        showBasketRevalidateError={showBasketRevalidateError}
        savedCard={savedCard}
        initiateExpressCheckout={initiateExpressCheckout}
      />
      {/* Footer enable when login with last payment */}
    </>
  ) : (
    <></>
  )
}

export default BasketExpressCheckout
