import { Fragment, useEffect, useState } from 'react'
import Link from 'next/link'
import {
  ArrowLeft,
  Cross,
  GreenCouponIcon,
  Heart,
  OrangeSpinner,
} from '@components/icons'
import {
  BAG_UPDATED,
  CLOSE_PANEL,
  GENERAL_BAG,
  GENERAL_TOTAL_SAVINGS,
  INSUFFICIENT_STOCK,
  MOVED_TO_WISH,
  PROD_ADDED_SUCCESSFULLY,
} from '@components/utils/textVariables'
import { isEmpty as isEmptyObject } from '@framework/utils/lodash'
import { Dialog, Transition } from '@headlessui/react'
import CartShippingHeader from './CartShippingHeader'
import EmptyCart from './EmptyCart'
import CartItemsSection from './CartItemsSection'
import CartFooter from './CartFooter'
import AlertRibbon from '@components/ui/AlertRibbon'
import RemoveItemPopup from './RemoveItemPopup'
import SizeChangePopup from './SizeChangePopup'
import { useUI } from '@components/ui'
import { matchStrings, priceFormat } from '@framework/utils/parse-util'
import useSection from 'hooks/useSection'
import {
  APPLY_JUSPAY_OFFERS_DISABLED,
  HIDE_TOTAL_SAVING_MOBILE_HEADER,
} from '@framework/utils/constants'
import { getJusPayOffers } from '@framework/utils/juspay-util'
import { recordGA4Event } from '@components/services/analytics/ga4'
import { getCategories, getCurrentPage } from '@framework/utils/app-util'
import { API_STATUS, EmptyGuid } from '@components/utils/constants'
import { GA_EVENT } from 'hooks/ga_event.modal'
import { useMoEngageEvent } from 'hooks/useMoengageEvent'
import useCart from '@components/services/cart'
import { handleRemoveEvents } from './helper'
import { IDeviceInfo } from '@components/ui/context'
import { ConvenienceFee } from '../convenienceFee'
import { useGoogleAnalyticsEvent } from 'hooks/useGAEvent'
import Cookies from 'js-cookie'
import { LocalStorage } from '@components/utils/payment-constants'

interface ICartSidebarViewIndex {
  handleClose: (
    closeSidebar: any,
    currentPage: string,
    previousPath: string
  ) => void
  currentPage: string
  previousPath: string
  altRelatedProducts: any
  deviceInfo: IDeviceInfo
  wishlistLoader: boolean
  reValidateData: any
  handleQuickAddToBag: (product: any) => void
  showRemove: (products: Array<any> | any) => void
  basketPromos: any
  fetchRelatedProducts: (prodId: string) => void
  getBasketPromos: (basketId: string) => void
  relatedProducts: any
  savedUPIs: Array<any> | undefined
  setBasketReValidate: (prop: any) => void
  preferredPaymentMethod: any
  config: any
  savedCards: Array<any> | undefined
  isUserLoggedIn: boolean
  fetchBasketReValidate: () => void
  isRemoveProdConfirmationOpen: boolean
  isLoading: any
  handleCloseRemoveModal: (val: boolean) => void
  setIsLoading: (props: any) => void
  removedProduct: Array<any> | any
  isSizeDialogOpen: boolean
  loaderState: any
  handleCloseSizeModal: (val: boolean) => void
  setSelectedProduct: (product: any) => void
  setProductSizes: any
  setVariantProducts: any
  buttonConfig: any
  productSizes: any
  SizeChart: any
  currentVariantData: any
  isSizeChartEnable: string
  variantProducts: any
  selectedProduct: any
  setCurrentVariantData: (props: string) => void
  convenienceData: any
  handleSoftAddProduct?: (props: any) => void
}

const CartSidebarViewIndex = (props: ICartSidebarViewIndex) => {
  const {
    handleClose,
    currentPage,
    previousPath,
    altRelatedProducts,
    deviceInfo,
    wishlistLoader,
    reValidateData,
    handleQuickAddToBag,
    showRemove,
    basketPromos,
    fetchRelatedProducts,
    getBasketPromos,
    relatedProducts,
    savedUPIs,
    setBasketReValidate,
    preferredPaymentMethod,
    config,
    savedCards,
    isUserLoggedIn,
    fetchBasketReValidate,
    isRemoveProdConfirmationOpen,
    isLoading,
    handleCloseRemoveModal,
    setIsLoading,
    removedProduct,
    isSizeDialogOpen,
    loaderState,
    handleCloseSizeModal,
    setSelectedProduct,
    setProductSizes,
    setVariantProducts,
    buttonConfig,
    productSizes,
    SizeChart,
    currentVariantData,
    isSizeChartEnable,
    variantProducts,
    selectedProduct,
    setCurrentVariantData,
    convenienceData,
    handleSoftAddProduct,
  } = props
  const [totalDiscount, setTotalDiscount] = useState(0)
  const [lastCartItemProductId, setLastCartItemProductId] = useState('')
  const [paymentOffers, setPaymentOffers] = useState<any | undefined>(undefined)
  const [showConvenienceFee, setConvenienceFee] = useState(false)
  const [loader, setLoader] = useState(API_STATUS.NOT_STARTED)
  const trackGoogleAnalyticsEvent = useGoogleAnalyticsEvent()

  const {
    closeSidebar,
    cartItems,
    displaySmallAlert,
    user,
    basketId,
    setCartItems,
    setAlert,
  } = useUI()
  const { handleSection } = useSection()
  const trackMoEngageEvent = useMoEngageEvent()
  const { addToCart } = useCart()

  const { isMobile, isIPadorTablet } = deviceInfo

  let deviceCheck = ''
  if (isMobile || isIPadorTablet) {
    deviceCheck = 'Mobile'
  } else {
    deviceCheck = 'Desktop'
  }

  const handleItem = (
    product: any | Array<any>,
    type = 'increase',
    autoCompute = false,
    newQty = 0,
    clickEvent = ''
  ) => {
    const asyncHandleItem = async (product: any) => {
      const data: any = {
        basketId,
        productId: product.id,
        stockCode: product.stockCode,
        displayOrder: product.displayOrder,
        qty: -1,
      }

      if (autoCompute) {
        data.qty = newQty - product?.qty
        if (currentPage) {
          recordGA4Event(window, GA_EVENT.SELECT_QUANTITY, {
            category: product?.categoryItems?.length
              ? product?.categoryItems[0]?.categoryName
              : '',
            final_quantity: newQty,
            current_page: currentPage,
            number_of_plus_clicked: 1,
            number_of_minus_clicked: 0,
            loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
          })
        }
      } else {
        if (type === 'increase') {
          data.qty = 1
          if (currentPage) {
            recordGA4Event(window, GA_EVENT.SELECT_QUANTITY, {
              category: product?.categoryItems?.length
                ? product?.categoryItems[0]?.categoryName
                : '',
              final_quantity: newQty,
              current_page: currentPage,
              number_of_plus_clicked: 1,
              number_of_minus_clicked: 0,
              loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
            })
          }
        }
      }
      let productAvailability = 'Yes'
      if (product?.currentStock > 0) {
        productAvailability = 'Yes'
      } else {
        productAvailability = 'No'
      }
      if (type === 'delete') {
        data.qty = 0
        // setIsLoading({ action: 'delete', state: true })
        handleRemoveEvents(
          product,
          user,
          trackMoEngageEvent,
          trackGoogleAnalyticsEvent,
          previousPath
        )
      }
      try {
        const item = await addToCart(data, type, { product })
        const _basketPromos: any = await getBasketPromos(basketId)
        setCartItems(item)
        if (type !== 'delete') {
          if (
            item?.message &&
            !matchStrings(item?.message, PROD_ADDED_SUCCESSFULLY, true)
          ) {
            setAlert({ type: 'error', msg: INSUFFICIENT_STOCK })
          } else if (item?.id === EmptyGuid) {
            setAlert({ type: 'error', msg: INSUFFICIENT_STOCK })
          } else {
            setAlert({ type: 'success', msg: BAG_UPDATED })
          }
        }
        if (item?.lineItems?.length) {
          const lastItemProductId =
            item?.lineItems[item?.lineItems?.length - 1]?.productId
          await fetchRelatedProducts(lastItemProductId)
        }
        // setIsLoading({ action: '', state: false })

        if (type === 'delete') {
          handleCloseRemoveModal(false)
          if (clickEvent === 'wishlist') {
            setAlert({ type: 'success', msg: MOVED_TO_WISH })
          }
        }
        setIsLoading({ action: '', state: false })
      } catch (error) {
        setIsLoading({ action: '', state: false })
        console.error(':: error ', error)
      }
    }

    if (product && product?.length) {
      product?.forEach((product: any) => {
        asyncHandleItem(product)
        setBasketReValidate([])
      })
    } else if (product?.productId) {
      asyncHandleItem(product)
    }
  }

  const viewWishlist = () => {
    if (currentPage) {
      recordGA4Event(window, GA_EVENT.WISHLIST, {
        ecommerce: {
          header: 'Menu Bar',
          current_page: currentPage,
          loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
        },
      })
    }
  }

  const getJusPayPromos = async (cartItems: any) => {
    const data = {
      order: {
        amount: cartItems?.grandTotal?.raw?.withTax?.toString(),
        currency: cartItems?.baseCurrency,
      },
    }
    const authToken =
      localStorage?.getItem(LocalStorage.Key.userEncryptedDetails) ?? ''
    const { data: offersResult } = await getJusPayOffers(
      data,
      authToken,
      user?.userId
    )

    setPaymentOffers(offersResult)
  }

  useEffect(() => {
    if (currentPage) {
      const clickSource = localStorage?.getItem('isFromCartIcon')
      recordGA4Event(window, GA_EVENT.VIEW_CART, {
        ecommerce: {
          items: cartItems?.lineItems?.map((items: any, itemId: number) => ({
            item_name: items?.name,
            item_id: items?.sku,
            price: items?.price?.raw?.withTax,
            item_brand: items?.brand,
            item_category2: getCategories(items).category2,
            item_category: getCategories(items).category,
            item_variant: items?.colorName,
            item_list_name: getCategories(items).category2,
            item_list_id: '',
            index: itemId,
            quantity: items?.qty,
            item_var_id: items?.stockCode,
          })),
          device: deviceCheck,
          current_page: getCurrentPage(previousPath),
          clicked_source: clickSource != '' ? clickSource : 'add_to_cart',
          loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
        },
      })
    }
  }, [cartItems?.lineItems?.length])

  //CALCULATE TOTAL DISCOUNT PRICE SAVING IN ALL PRODUCTS
  useEffect(() => {
    let totalPriceSaving = 0
    let updateQtySaving = 0
    cartItems?.lineItems?.forEach((product: any) => {
      if (product?.listPrice?.raw?.withTax > product?.price?.raw?.withTax) {
        const saving =
          product?.listPrice?.raw?.withTax - product?.price?.raw?.withTax
        updateQtySaving = saving * product?.qty
        totalPriceSaving = totalPriceSaving + updateQtySaving
      }
    })

    setTotalDiscount(totalPriceSaving + cartItems?.discount?.raw?.withTax)

    if (cartItems?.lineItems?.length) {
      const lastCartItemProductId =
        cartItems?.lineItems[cartItems?.lineItems?.length - 1]?.productId
      setLastCartItemProductId(lastCartItemProductId)
    }
    const handleAsync = async (cartItems: any) => {
      if (!APPLY_JUSPAY_OFFERS_DISABLED) {
        await getJusPayPromos(cartItems)
      } else {
        setPaymentOffers([])
      }
    }
    handleAsync(cartItems)
  }, [cartItems])

  const itemsInBag = () => {
    return cartItems?.lineItems
      ?.map((item: any) => item.qty)
      .reduce((sum: number, current: number) => sum + current, 0)
  }

  const handleViewItem = () => {
    handleSection('Bag')
  }

  const isEmpty: boolean = isEmptyObject(cartItems)
    ? true
    : cartItems?.lineItems?.length === 0

  const relatedProductData = relatedProducts?.relatedProducts?.filter(
    (x: any) => x?.itemType != 10
  )

  const chipestPrice = cartItems?.promotionsApplied?.find(
    (x: any) => x?.promoType == 4
  )?.discountAmt?.raw?.withTax

  const chipestProduct = cartItems?.lineItems?.find(
    (x: any) => x?.price?.raw?.withTax == chipestPrice && x?.qty == 1
  )

  const chipestProductId = chipestProduct?.id

  return (
    <>
      {loader === API_STATUS.LOADING && (
        <div className="bg-black/[.5] cs-loader-box fixed top-0 right-0 z-50 flex items-center justify-center w-screen h-screen z-50">
          <OrangeSpinner className="animate-spin" />
        </div>
      )}
      {/* MAIN CART VIEW */}
      <Transition.Root show={true} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-hidden"
          onClose={() => handleClose(closeSidebar, currentPage, previousPath)}
        >
          <div className="">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-400"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-400"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Overlay className="absolute inset-0 overflow-hidden side-overlay bg-black/[.5]" />
            </Transition.Child>
            <div className="fixed inset-y-0 right-0 flex max-w-full pl-0 sm:pl-10 z-999">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-400"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-400"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="width-cart">
                  <div className="flex flex-col w-full h-full overflow-x-hidden overflow-y-auto bg-white shadow-xl custom-scroll">
                    <div className="sticky top-0 z-50 flex flex-col w-full pt-5 bg-white border-b border-gray-200">
                      <div className="flex items-start justify-between px-4 pb-3 sm:px-8">
                        <div className="relative flex -top-1">
                          <Dialog.Title className="flex text-lg font-medium text-gray-900 sm:hidden dark:text-black">
                            <button
                              type="button"
                              className="p-0 mr-5 text-black hover:text-black dark:text-black"
                              onClick={() =>
                                handleClose(
                                  closeSidebar,
                                  currentPage,
                                  previousPath
                                )
                              }
                            >
                              <span className="sr-only">{CLOSE_PANEL}</span>
                              <ArrowLeft
                                className="block w-6 h-6 sm:hidden"
                                aria-hidden="true"
                              />
                            </button>
                          </Dialog.Title>
                          <span className="text-2xl font-bold text-black dark:text-black">
                            {GENERAL_BAG}
                          </span>
                          {itemsInBag() > 0 ? (
                            <>
                              <span className="pl-2 mt-3 text-xs font-normal text-gray-400 dark:text-black">
                                {itemsInBag()}
                                {itemsInBag() > 1 ? ' items' : ' item'}
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="pl-2 mt-3 text-xs font-normal text-gray-400 dark:text-black">
                                Empty
                              </span>
                            </>
                          )}
                        </div>
                        <div className="text-right item-right">
                          <Dialog.Title className="hidden text-lg font-medium text-gray-900 sm:block dark:text-black">
                            <button
                              type="button"
                              className="p-0 mr-0 text-black hover:text-black dark:text-black"
                              onClick={() =>
                                handleClose(
                                  closeSidebar,
                                  currentPage,
                                  previousPath
                                )
                              }
                            >
                              <span className="sr-only">{CLOSE_PANEL}</span>
                              <Cross
                                className="hidden w-6 h-6 sm:block"
                                aria-hidden="true"
                              />
                            </button>
                          </Dialog.Title>
                          <span className="flex sm:hidden">
                            <Link
                              legacyBehavior
                              href="/my-account/wishlist"
                              onClick={() => {
                                handleClose(
                                  closeSidebar,
                                  currentPage,
                                  previousPath
                                )
                                viewWishlist()
                              }}
                              passHref
                            >
                              <a
                                onClick={() => {
                                  handleClose(
                                    closeSidebar,
                                    currentPage,
                                    previousPath
                                  )
                                  viewWishlist()
                                }}
                              >
                                <Heart className="w-6 h-6 text-black" />
                              </a>
                            </Link>
                          </span>
                        </div>
                      </div>
                      {HIDE_TOTAL_SAVING_MOBILE_HEADER &&
                        totalDiscount > 0 &&
                        cartItems?.lineItems?.length > 0 && (
                          <div className="flex flex-col w-full px-4 py-1 border-b bg-cart-sidebar-green-light sm:px-8">
                            <h3 className="font-semibold text-14 text-green-dark">
                              {priceFormat(totalDiscount)}{' '}
                              {GENERAL_TOTAL_SAVINGS}
                            </h3>
                          </div>
                        )}
                    </div>
                    {!HIDE_TOTAL_SAVING_MOBILE_HEADER &&
                      totalDiscount > 0 &&
                      cartItems?.lineItems?.length > 0 && (
                        <>
                          <div className="w-full border-t-[20px] border-[#f7f7f7]"></div>
                          <div className="flex items-center sticky z-10 top-[60px] w-full px-4 py-1.5 border-b bg-cart-sidebar-green-light sm:px-8 ">
                            <GreenCouponIcon />
                            <p className="font-semibold text-[14px] text-green-dark ml-2">
                              {priceFormat(totalDiscount)}
                              <span className="text-[14px] ml-0.5 font-normal text-[#000000A3]">
                                {GENERAL_TOTAL_SAVINGS}
                              </span>
                            </p>
                          </div>
                        </>
                      )}
                    <CartShippingHeader />
                    {isEmpty ? (
                      <EmptyCart
                        altRelatedProducts={altRelatedProducts}
                        deviceInfo={deviceInfo}
                        wishlistLoader={wishlistLoader}
                        currentPage={currentPage}
                        previousPath={previousPath}
                        setLoader={setLoader}
                      />
                    ) : (
                      <>
                        <CartItemsSection
                          reValidateData={reValidateData}
                          chipestProductId={chipestProductId}
                          deviceInfo={deviceInfo}
                          handleViewItem={handleViewItem}
                          handleQuickAddToBag={handleQuickAddToBag}
                          handleItem={handleItem}
                          showRemove={showRemove}
                          chipestProduct={chipestProduct}
                          basketPromos={basketPromos}
                          paymentOffers={paymentOffers}
                          getBasketPromos={getBasketPromos}
                          relatedProductData={relatedProductData}
                          wishlistLoader={wishlistLoader}
                          currentPage={currentPage}
                          previousPath={previousPath}
                          setLoader={setLoader}
                          convenienceData={convenienceData}
                          handleSoftAddProduct={handleSoftAddProduct}
                        />
                      </>
                    )}
                    <CartFooter
                      isEmpty={isEmpty}
                      cartItems={cartItems}
                      reValidateData={reValidateData}
                      showRemove={showRemove}
                      savedUPIs={savedUPIs}
                      deviceInfo={deviceInfo}
                      setBasketReValidate={setBasketReValidate}
                      preferredPaymentMethod={preferredPaymentMethod}
                      config={config}
                      savedCards={savedCards}
                      isUserLoggedIn={isUserLoggedIn}
                      fetchBasketReValidate={fetchBasketReValidate}
                      convenienceData={convenienceData}
                      setConvenienceFee={setConvenienceFee}
                    />
                  </div>
                </div>
              </Transition.Child>
            </div>
            {displaySmallAlert && (
              <div className="cs-bottom-fixed">
                <AlertRibbon />
              </div>
            )}
          </div>
        </Dialog>
      </Transition.Root>

      {/* REMOVE CONFIRMATION PANEL */}
      <RemoveItemPopup
        isRemoveProdConfirmationOpen={isRemoveProdConfirmationOpen}
        isLoading={isLoading}
        handleCloseRemoveModal={handleCloseRemoveModal}
        setIsLoading={setIsLoading}
        handleItem={handleItem}
        removedProduct={removedProduct}
      />

      {/* SIZE CHANGE CONFIRMATION PANEL */}
      <SizeChangePopup
        show={isSizeDialogOpen}
        loaderState={loaderState}
        handleCloseSizeModal={handleCloseSizeModal}
        setSelectedProduct={setSelectedProduct}
        setProductSizes={setProductSizes}
        setVariantProducts={setVariantProducts}
        buttonConfig={buttonConfig}
        productSizes={productSizes}
        deviceInfo={deviceInfo}
        SizeChart={SizeChart}
        currentVariantData={currentVariantData}
        isSizeChartEnable={isSizeChartEnable}
        variantProducts={variantProducts}
        selectedProduct={selectedProduct}
        setCurrentVariantData={setCurrentVariantData}
      />

      {/* convenience fee */}
      <ConvenienceFee
        showConvenienceFee={showConvenienceFee}
        setConvenienceFee={setConvenienceFee}
        title={convenienceData?.title}
        description={convenienceData?.description}
      />
    </>
  )
}

export default CartSidebarViewIndex
