import { useUI } from '@components/ui';
import { openLoginView } from '@framework/utils/app-util';
import { useRouter } from 'next/router';
import React from 'react';

// Define error messages with optional login link
const errorMessages: Record<string, { message: string; loginMessage?: string; showLoginLink?: boolean }> = {
  'OnlyForRegisteredUser': {
    message: "Hold on! You’re not logged in yet. Log in & try again.",
    loginMessage: 'Hold on! You’re not logged in yet',
    showLoginLink: true,
  },
  'InvalidOrInactivePromo': {
    message: "Oops! This promo code is not valid.",
  },
  'OnlyOneTimePromo': {
    message: "Oops! This is a one-time offer which you've redeemed earlier. Try out our other coupons!",
  },
  'AllowedOnlyOnFirstOrder': {
    message: "Uh oh! This offer is only for 1st orders. Try out our other coupons!",
  },
  'OneTimeForAllPromo': {
    message: "Oh no! This treat is a one-time delight. Explore our other promotions for more ways to save!",
  },
};

// Error message component
const ErrorMessageDisplay: any = ({ errorCode }:any) => {
  const router = useRouter();
  const { setLoginCustomTitle, setSidebarView } = useUI();

  const handleOpenLoginView = () => {
    setLoginCustomTitle('Login to Proceed');
    localStorage.removeItem('routeRedirectionToCart');
    openLoginView(router.asPath);
    setTimeout(() => {
      setSidebarView('NEW_LOGIN_VIEW');
    }, 1000);
  };

  const error = errorMessages[errorCode] || { message: 'Unknown error.' };

  return (
    <div className="mb-2 mt-2 text-xs text-red-400">
      {error.showLoginLink ? (
        <>
          {error.loginMessage}{' '} 
          <button
            className="hover:text-orange-500 font-semibold text-orange-500"
            onClick={handleOpenLoginView}
          >
            Log in & try again.
          </button>
        </>
      ) : (
        error.message
      )}
    </div>
  );
};

export const getErrorMessage = (errorCode: string): string => {
  return errorMessages[errorCode]?.message || 'Unknown error';
};

export default ErrorMessageDisplay;